import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { MatDialogModule } from '@angular/material/dialog'

import { InfiniteScrollModule } from 'ngx-infinite-scroll'

import { BuilderMaterialModule } from '@builder/common/ng-material'
import { AlphaLocaleModule } from '@builder/common/lang/locale.module'
import { CardModule } from '@builder/common/cards/card.module'
import { ImagePositionModule } from '@builder/common/directives/image-position/image-position.module'
import { BuilderSelectModule } from '@builder/common/forms/builder-select/builder-select.module'
import { BuilderIconModule } from '@builder/common/icons/ab-icon.module'
import { CommonPipesModule } from '@builder/common/pipes/common-pipes.module'

import {
  SearchResultsComponent,
  CardTypeTemplateDirective
} from './search-results.component'
import { SearchFormModule } from '../search-form/search-form.module'
import { PopularSearchTermsModule } from '../popular/popular-search-terms.module'
import { SearchResultListComponent } from './search-result-list/search-result-list.component'
import { SearchResultComponent } from './search-result/search-result.component'
import {
  FilterInUseCategoriesPipe,
  FilterInUseProductsPipe,
  PostsFilterPipe,
  PostsFilterUpdatedPipe
} from './search-results.pipes'
import { MatSelectModule } from '@angular/material/select'
import { SearchFiltersDialogComponent } from './search-filters-dialog/search-filters-dialog.component'
import { SearchFilterModule } from '../search-filter/search-filter.module'
import { LookupAlphaModule } from '../lookup-alpha/lookup-alpha.module'

const components = [
  SearchResultsComponent,
  SearchResultListComponent,
  SearchFiltersDialogComponent
]

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    BuilderMaterialModule,
    InfiniteScrollModule,
    CardModule,
    BuilderIconModule,
    BuilderSelectModule,
    AlphaLocaleModule,
    SearchFormModule,
    PopularSearchTermsModule,
    ImagePositionModule,
    SearchFilterModule,
    CommonPipesModule,
    MatSelectModule,
    MatDialogModule,
    LookupAlphaModule,
    SearchResultComponent
  ],
  declarations: [
    CardTypeTemplateDirective,
    PostsFilterPipe,
    PostsFilterUpdatedPipe,
    FilterInUseProductsPipe,
    FilterInUseCategoriesPipe,
    components
  ],
  providers: [PostsFilterUpdatedPipe],
  exports: components,
  entryComponents: components
})
export class SearchResultsModule {}
