import {
  Component,
  ViewChild,
  ChangeDetectorRef,
  QueryList,
  ViewChildren,
  Directive,
  AfterViewInit,
  OnInit,
  HostBinding
} from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { MatTabGroup } from '@angular/material/tabs'
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout'

import { Observable, combineLatest, of } from 'rxjs'
import { map, switchMap, takeUntil, tap } from 'rxjs/operators'

import { CardComponent } from '@builder/common/cards/card.component'
import { AppTheme } from '@builder/common/util/themeSupports'
import { WithDestroy } from '@builder/common/mixins/with-destroy'
import { LessonService } from '@builder/training/lessons/lesson-service'

import { SearchFormComponent } from '../search-form/search-form.component'
import { CurrentUser } from '@builder/users'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { SearchFiltersDialogComponent } from './search-filters-dialog/search-filters-dialog.component'
import { SearchResultListComponent } from './search-result-list/search-result-list.component'
import { SearchFilterComponent } from '../search-filter/search-filter.component'
import { Resource, ResourceService } from '@builder/resources'
import { AlphasService } from '@builder/alphas/service'
import { ProductService } from '@builder/products/product.service'
import { Lesson } from '@builder/training'

export const SESSION_STORAGE_KEY_SEARCH_FILTERS = 'storedSearchFilters'

export interface IResultSet {
  query: any
  posts: Array<Resource>
  total: number
}

@Directive({
  selector: '[cardTypeTemplate]'
})
export class CardTypeTemplateDirective {}

/**
 *
 */
@Component({
  selector: 'search-results',
  templateUrl: 'search-results.component.html',
  styleUrls: ['search-results.component.less']
})
export class SearchResultsComponent extends WithDestroy() implements OnInit {
  showFilter: boolean = true

  @ViewChild(MatTabGroup) tabGroup: MatTabGroup
  @ViewChild('searchForm', { static: true }) searchForm: SearchFormComponent
  @ViewChild(SearchResultListComponent, { static: false })
  SearchResultListComponent: SearchResultListComponent

  @ViewChildren(CardComponent) cards: QueryList<CardComponent>
  @ViewChild(SearchResultListComponent, { static: false })
  searchResultSet: SearchResultListComponent
  @ViewChild(SearchFilterComponent, { static: true })
  searchFiltersComponent: SearchFilterComponent

  public searchTerm: string

  public results: any
  searchResults$: Observable<Array<Resource>>

  public resourceTypes: Array<any> = []
  public learningCategories: Array<any> = []
  public learningTypes: Array<any> = []

  public selectedSeries
  public selectedResourceType
  public selectedLearningCategory
  public selectedLearningType

  public initialTabIndex = 0
  public showMobileFilters: boolean = false
  public resultsCount = 0
  public filteredCount = 0
  public filterParams: { [index: string]: any } = {}
  public count: { current: number; total: number } | null = null
  public isLookupAlphaVisible = true

  protected i18n = {
    all: $localize`:common.words|All@@commonWordAll:All`,
    video: $localize`:common.words|Video@@commonWordVideo:Video`,
    videos: $localize`:common.words|Videos@@commonWordVideos:Videos`,
    document: $localize`:common.words|Document@@commonWordDocument:Document`,
    documents: $localize`:common.words|Documents@@commonWordDocuments:Documents`,
    article: $localize`:common.words|Article@@commonWordArticle:Article`,
    articles: $localize`:common.words|Articles@@commonWordArticles:Articles`,
    module: $localize`:common.words|Common Word - Module@@commonWordsModule:Module`,
    modules: $localize`:common.words|Common Words - Modules@@commonWordModules:Modules`
  }

  constructor(
    private route: ActivatedRoute,
    lessonService: LessonService,
    public router: Router,
    public theme: AppTheme,
    private cd: ChangeDetectorRef,
    private user: CurrentUser,
    private dialog: MatDialog,
    private productService: ProductService,
    private alphasService: AlphasService,
    private resourceService: ResourceService,
    bo: BreakpointObserver
  ) {
    super()

    this.resourceTypes = [
      { viewValue: this.i18n.all },
      { viewValue: this.i18n.videos, value: 'video' },
      { viewValue: this.i18n.document, value: 'document' }
    ]

    this.selectedResourceType = this.resourceTypes[0]

    this.learningTypes = [
      { viewValue: this.i18n.all },
      { viewValue: this.i18n.videos, value: 'video' },
      { viewValue: this.i18n.articles, value: 'article' },
      { viewValue: this.i18n.modules, value: 'playlist' }
    ]

    this.selectedLearningType = this.learningTypes[0]

    route.queryParams.subscribe((params) => {
      if (params.q) {
        this.searchTerm = params.q
        sessionStorage.setItem(
          'savedSearchQuery-' + this.user.id,
          this.searchTerm
        )
        this.cd.markForCheck()
      } else {
        this.searchTerm = ''
      }
    })

    lessonService.getLessonCategories().subscribe((cats) => {
      this.learningCategories = [
        { viewValue: this.i18n.all },
        ...cats.map((cat) => {
          return { viewValue: cat.name, value: cat.slug }
        })
      ]
      this.selectedLearningCategory = this.learningCategories[0]
      this.cd.markForCheck()
    })

    // nav state may indicate which tab to start on
    const navState = this.router.getCurrentNavigation().extras.state || {}
    const fragmentTabActivate = navState.section
      ? ['all', 'material', 'promotional', 'training'].indexOf(navState.section)
      : 0
    if (fragmentTabActivate >= 0) {
      this.initialTabIndex = fragmentTabActivate
    }

    const lc: Observable<BreakpointState> = bo.observe(['(max-width:768px)'])

    lc.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      this.showMobileFilters = result.matches
      if (!this.showMobileFilters) {
        this.dialog.closeAll()
      }
    })
  }

  ngOnInit(): void {
    this.filterParams = this.getStoredSearchFilters()

    this.searchResults$ = this.route.data.pipe(
      map((params) => params.results),
      tap((results: IResultSet) => {
        this.resultsCount = results
          ? Object.values(results)
              .map(({ total }) => total)
              .reduce((acc, curr) => acc + curr, 0)
          : 0
      }),
      switchMap((results) => {
        return !Boolean(results)
          ? of([])
          : combineLatest([
              this.alphasService.userAlphas.pipe(
                map(({ activeProducts }) => activeProducts)
              ),
              this.productService.getValidProducts()
            ]).pipe(
              map(([activeProducts, validProducts]) => {
                return this.weightActiveProductsResults(
                  this.combineResults(
                    this.filterResultsByValidProducts(
                      results,
                      validProducts,
                      activeProducts
                    )
                  ),
                  activeProducts
                )
              })
            )
      })
    )
  }

  hideLookupAlpha(): void {
    this.isLookupAlphaVisible = false
  }

  public onSeriesChange(value): void {
    this.selectedSeries = value
  }

  public onResourceTypeChange(value): void {
    this.selectedResourceType = value
  }

  public onLearningCategoryChange(value): void {
    this.selectedLearningCategory = value
  }

  public onLearningTypeChange(value): void {
    this.selectedLearningType = value
  }

  openDialog() {
    const dialogConfig = new MatDialogConfig()

    dialogConfig.autoFocus = true

    dialogConfig.data = {
      id: Math.floor(Math.random() * 100 + 1).toString(),
      title: $localize`:searchFiltersDialog.text|Search Filters Dialog - Filter Search Results@@searchFiltersDialogTitle:Filter Search Results`,
      filters: this.filterParams
    }

    const dialogRef = this.dialog.open(
      SearchFiltersDialogComponent,
      dialogConfig
    )

    dialogRef.afterClosed().subscribe((data) => {
      if (data) this.setStoredSearchFilters(data)
    })
  }

  onFilteredCount(count: number): void {
    this.filteredCount = count
  }

  public updateResultCount(event: { current: number; total: number }): void {
    this.count = event ? event : null
    this.cd.markForCheck()
  }

  toggleFilterSection(event: Event) {
    event.preventDefault()
    this.showFilter = !this.showFilter
  }

  handleValueChange(newValue: any) {
    this.setStoredSearchFilters(newValue)
    this.cd.markForCheck()
  }

  private getStoredSearchFilters() {
    try {
      const parsedValue = JSON.parse(
        sessionStorage.getItem(SESSION_STORAGE_KEY_SEARCH_FILTERS)
      )
      return parsedValue ? parsedValue : {}
    } catch (e) {
      return {}
    }
  }

  private setStoredSearchFilters(val: any) {
    this.filterParams = val

    if (val == null) {
      sessionStorage.removeItem(SESSION_STORAGE_KEY_SEARCH_FILTERS)
    } else {
      sessionStorage.setItem(
        SESSION_STORAGE_KEY_SEARCH_FILTERS,
        JSON.stringify(val)
      )
    }
  }

  private combineResults({ learning, promotional, materials }): Resource[] {
    return [
      ...materials.posts.map(({ categories, ...other }) => ({
        ...other,
        themes: categories,
        category: 'material'
      })),
      ...learning.posts.map(({ categories, ...other }) => ({
        ...other,
        themes: categories,
        category: 'learning'
      })),
      ...promotional.posts.map(({ categories, ...other }) => ({
        ...other,
        themes: categories,
        category: 'promotional'
      }))
    ]
  }

  private filterResultsByValidProducts(
    results,
    validProducts,
    userActiveProducts
  ) {
    for (const group in results) {
      if (group === 'learning') {
        results[group].posts = results[group].posts.map((p) => new Lesson(p))
      } else {
        results[group].posts = results[group].posts
          .map((p) => {
            const r = new Resource(p)
            this.resourceService.assignAccessResourceErrors(
              r,
              userActiveProducts
            )
            this.resourceService.assignResourceProduct(r)
            r.products = r.products.filter((product) =>
              validProducts.some(
                (validProduct) => validProduct.id === product.id
              )
            )

            return r
          })
          .filter((r) => r.products.length > 0)
      }
    }
    return results
  }

  weightActiveProductsResults(list, activeProducts): Resource[] {
    const result = list
      .map((post) =>
        post.hasOwnProperty('products') &&
        post.products.some(({ id, blog_id }) =>
          activeProducts.some((p) => p.id === id && p.blog_id === blog_id)
        )
          ? {
              ...post,
              weight: post.weight + 2,
              bumped: true
            }
          : post
      )
      .sort((a, b) => b.weight - a.weight)

    return result
  }
}
