import { Injectable } from '@angular/core'

const ACCEPTED_PARAMS: { name: string; type: string }[] = [
  { name: 'courseLanguage', type: 'string' },
  { name: 'region', type: 'string' },
  { name: 'product', type: 'string' },
  { name: 'isStudentLed', type: 'string' },
  { name: 'isLegalAge', type: 'boolean' },
  { name: 'deliveryMode', type: 'string' },
  { name: 'isCatholicHeading', type: 'boolean' },
  { name: 'subtitles', type: 'string' },
  { name: 'role', type: 'string' },
  { name: 'otherDescription', type: 'string' },
  { name: 'startDate', type: 'string' }
]
@Injectable({
  providedIn: 'root'
})
export class UrlRegistrationService {
  constructor() {}

  public getParams() {
    const urlParams = new URL(document.location.href).searchParams
    const params: any = {}

    urlParams.forEach((value: any, key: string) => {
      let param = ACCEPTED_PARAMS.find((p) => p.name === key)
      if (!param) {
        return
      }
      if (param.type === 'boolean') {
        value = value === '1' || value === 'true'
      }
      params[key] = value
    })

    return params
  }

  public hasParams(): boolean {
    const params = this.getParams()
    return Object.keys(params).includes('product')
  }
}
